import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { friendlyLabelNames, friendlyOptionNames, stylePresetFriendlyNames } from '../Shared/Constants';

const CollapsibleSection = ({ label, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <button onClick={() => setIsOpen(!isOpen)}>
        {label}
      </button>
      {isOpen && <div>{children}</div>}
    </div>
  );
};

const LeftSidebar = ({
  text,
  weight,
  setText,
  setWeight,
  otherValues,
  setOtherValues,
  engineValues,
  setEngineValues,
  counter,
  errorMessage
}) => {
  const [isOpen, setIsOpen] = useState(window.innerWidth > 768);
  const [localErrorMessage, setLocalErrorMessage] = useState(errorMessage);

  useEffect(() => {
    if (errorMessage) { // Only update if there's a new error message
      setLocalErrorMessage(errorMessage);
    }
  }, [errorMessage]);

  const renderErrorMessages = () => {
    if (typeof localErrorMessage === "object") {
      return (
        <ul>
          {Object.entries(localErrorMessage).map(([key, values]) => (
            <li key={key}>
              {key}: {values.join(', ')}
            </li>
          ))}
        </ul>
      );
    } else {
      return localErrorMessage;
    }
  };

  const clearErrors = () => {
    setLocalErrorMessage(null); // Clears the local error message state
  };


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsOpen(false); // Hide sidebar by default on mobile
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleTextInputChange = (event) => {
    const pattern = /^[a-zA-Z0-9\s.,!?'"()@#$%^&*[\]_\-+=<>~]*$/;
    if (pattern.test(event.target.value)) {
      setText(event.target.value);
    }
  };

  const handleWeightChange = (event) => {
    setWeight(Number(event.target.value));
  };

  const handleSliderChange = (event, key) => {
    const value = Number(event.target.value);
    setOtherValues(prevValues => ({
      ...prevValues,
      [key]: value
    }));
  };

  const renderSlider = (label, key, tooltip) => (
    <div className="slider-container">
      <label htmlFor={key}>
        {tooltip && (
          <div className="tooltip-wrapper">
            <span className="tooltip-icon">
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
            <span className="tooltip-text">{tooltip}</span>
          </div>
        )}
        {label}: {otherValues[key] !== undefined ? otherValues[key] : 5}
      </label>
      <input
        type="range"
        id={key}
        name={key}
        min="0"
        max="10"
        value={otherValues[key] !== undefined ? otherValues[key] : 5}
        onChange={(e) => handleSliderChange(e, key)}
      />
    </div>
  );

  const handleEngineValueChange = (event, key) => {
    const value =
      key === 'stylePreset' || key === 'clip_guidance_preset' || key === 'sampler'
        ? event.target.value
        : Number(event.target.value);
    setEngineValues(prevValues => ({
      ...prevValues,
      [key]: value
    }));
  };

  const renderEngineSlider = (label, key, min, max, step) => (
    <div className="slider-container">
      <label htmlFor={key}>
        {label}: {engineValues[key]}
      </label>
      <input
        type="range"
        id={key}
        name={key}
        min={min}
        max={max}
        step={step}
        value={engineValues[key]}
        onChange={(e) => handleEngineValueChange(e, key)}
      />
    </div>
  );

  const renderDropdown = (key, options, tooltip) => {
    const friendlyLabel = friendlyLabelNames[key] || key;

    return (
      <div className="dropdown-container">
        <label htmlFor={key}>
          {tooltip && (
            <div className="tooltip-wrapper">
              <span className="tooltip-icon">
                <FontAwesomeIcon icon={faInfoCircle} />
              </span>
              <span className="tooltip-text">{tooltip}</span>
            </div>
          )}
          {friendlyLabel}:
        </label>
        <select
          id={key}
          value={engineValues[key]}
          onChange={(e) => handleEngineValueChange(e, key)}
        >
          {options.map(option => {
            const friendlyName = friendlyOptionNames[option] || stylePresetFriendlyNames[option] || option;
            return <option key={option} value={option}>{friendlyName}</option>;
          })}
        </select>
      </div>
    );
  };

  return (
    <>
      {/* Sidebar */}
      <div className={`sidebar left-bar ${isOpen ? 'open' : 'closed'}`}>
        {isOpen && (
          <>
            <div className="counter-section">
              <label htmlFor="counter-value">Cards Available</label>
              <span id="counter-value">{counter}</span>
            </div>

            {localErrorMessage && 
            <div className="error-message">
              <div className="message-content">
                <label htmlFor="error-message">Error:</label>
                <span id="error-message">{renderErrorMessages()}</span>
              </div>
              <button onClick={clearErrors} id="clear-errors-button">Clear Errors</button>
            </div>
            }

            <div className="message-section">
              <label htmlFor="placeholder-message">Message:</label>
              <p id="placeholder-message">Current limit is 10 active cards at a time.</p>
            </div>

          <div>
            <label htmlFor="text-input">Enter a custom prompt here:</label>
            <textarea
              id="text-input"
              value={text}
              onChange={handleTextInputChange}
              rows="10"
              cols="30"
              placeholder="Type here..."
              maxLength="500"
            />
            <label htmlFor="weight">Prompt: {weight}</label>
            <input
              type="range"
              id="weight"
              name="weight"
              min="0"
              max="10"
              value={weight}
              onChange={handleWeightChange}
            />
            {renderSlider('Card Name', 'cardName')}

            <CollapsibleSection label="Advanced Prompt Features">
              {renderSlider('Color', 'color')}
              {renderSlider('Type Line', 'typeLine')}
              {renderSlider('Keywords', 'keywords', 'Will add weight to use of the keywords of the card, if it has any, to the prompt.')}
              {renderSlider('Tokens', 'tokens', 'Will add any weight to use of tokens mentioned in the card text, if it has any, to the prompt.')}
            </CollapsibleSection>

            <CollapsibleSection label="Advanced Engine Features">
              {renderEngineSlider('Adherence to Prompt', 'cfg_scale', 0, 35, 1)}
              {renderEngineSlider('Detail Level', 'steps', 10, 50, 1)}
              {renderDropdown(
                'clip_guidance_preset',
                ['FAST_BLUE', 'FAST_GREEN', 'NONE', 'SIMPLE', 'SLOW', 'SLOWER', 'SLOWEST'],
                'Control how strongly the AI follows the prompt (faster = less detailed)'
              )}
              {renderDropdown(
                'sampler',
                ['DDIM', 'DDPM', 'K_DPMPP_2M', 'K_DPMPP_2S_ANCESTRAL', 'K_DPMPP_SDE', 'K_DPM_2', 'K_DPM_2_ANCESTRAL', 'K_EULER', 'K_EULER_ANCESTRAL', 'K_HEUN', 'K_LMS'],
                'Select the algorithm that shapes the image (affects speed and style)'
              )}
              {renderDropdown(
                'stylePreset',
                ['3d-model', 'analog-film', 'anime', 'cinematic', 'comic-book', 'digital-art', 'enhance', 'fantasy-art', 'isometric', 'line-art', 'low-poly', 'modeling-compound', 'neon-punk', 'origami', 'photographic', 'pixel-art', 'tile-texture']
              )}
            </CollapsibleSection>
          </div>
          </>
        )}
      </div>

      {/* Toggle Button (placed outside the sidebar) */}
      <button
        className={`toggle-button ${isOpen ? '' : 'collapsed'}`} 
        onClick={handleToggle}
      >
        {isOpen ? '' : ''}
      </button>
    </>
  );
};

export default LeftSidebar;
